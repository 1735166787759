import Vue from 'vue'

const namespaced = true

const user = {
  active: null,
  avatar: null,
  email: null,
  name: null,
  pending: null,
  role: null,
  slug: null,
  timezone: null
}

const state = {
  user
}

// getters
const getters = {
  user: state => {
    return state.user;
  }
}

// actions
const actions = {
  update({ commit }) {
    Vue.auth.fetch({
      success: function () {
        let payload = Vue.auth.user();
        commit('update', payload)
      }
    })
  },
  set({ commit }, payload) {
    commit('companies/setCompanies', payload.companies, { root: true })
    commit('update', payload)
  },
  revert({ commit }) {
    commit('revert')
  }
}

// mutations
const mutations = {
  update(state, payload) {
    state.user = payload;
  },
  revert(state) {
    state.user = user;
  }
}

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
