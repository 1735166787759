import App from '@/App.vue'
import axios from 'axios'

export default {
  listCompanies({ page, query }, cb) {
    axios.get('companies/list', { params: { page: page, query: query } })
      .then(response => cb(response.data))
      .catch(error => {
        cb([]);
        App.methods.globalRequestError(error);
      });
  }
}
