<template>
  <div v-sticky="{ zIndex: 999, stickyTop: 0 }" class="top-nav">
    <el-row>
      <div class="nav-container">
        <el-row class="logo-container">
          <a
            class="company-container has-cursor-pointer"
            @click="companyMenu = !companyMenu"
            v-if="companies || companies.length > 1"
            ref="companyButton"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logos.ca"
              class="nav-logo"
            ></svg>
            <img
              v-if="company && company.primary_logo"
              :src="company.primary_logo"
              class="company-logo"
            />
            <img
              v-if="company && company.secondary_logo"
              :src="company.secondary_logo"
              class="company-logo"
            />
            <font-awesome-icon
              icon="caret-down"
              fixed-width
            ></font-awesome-icon>
          </a>
          <div v-else-if="companies || companies.length === 1">
            <router-link :to="{ name: 'home' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1400 1065.8"
                v-html="logos.ca"
                class="nav-logo"
              ></svg>
              <img
                v-if="company && company.primary_logo"
                :src="company.primary_logo"
                class="company-logo"
              />
              <img
                v-if="company && company.secondary_logo"
                :src="company.secondary_logo"
                class="company-logo"
              />
            </router-link>
          </div>
          <div v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logos.ca"
              class="nav-logo"
            ></svg>
            <img
              v-if="company && company.primary_logo"
              :src="company.primary_logo"
              class="company-logo"
            />
            <img
              v-if="company && company.secondary_logo"
              :src="company.secondary_logo"
              class="company-logo"
            />
          </div>
        </el-row>
        <div class="main-container">
          <el-row class="middle-container">
            <breadcrumbs v-if="$root.windowWidth >= 768"></breadcrumbs>
          </el-row>

          <el-row class="right-container">
            <transition name="fade" mode="out-in">
              <el-button
                size="small"
                type="primary"
                v-if="
                  $route.name === 'month' &&
                  $auth.check(['admin', 'super']) &&
                  !editing
                "
                @click="toggleMonthEdit"
                >Edit</el-button
              >
              <div
                style="display: flex"
                v-if="
                  $route.name === 'month' &&
                  $auth.check(['admin', 'super']) &&
                  editing
                "
              >
                <el-dropdown
                  placement="bottom-end"
                  size="small"
                  trigger="click"
                  @command="newMenu"
                  class="new-dropdown"
                >
                  <el-button type="success" size="small">
                    <font-awesome-icon
                      icon="plus"
                      full-width
                    ></font-awesome-icon>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="new-dropdown-menu">
                    <el-dropdown-item command="campaign">
                      Add Campaign
                    </el-dropdown-item>
                    <el-dropdown-item command="asset">
                      Add Asset
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button size="small" type="primary" @click="toggleMonthEdit"
                  >View</el-button
                >
              </div>
            </transition>
            <a
              v-if="$auth.check(['admin', 'super'])"
              class="settings has-cursor-pointer"
              @click="adminMenu = !adminMenu"
              ref="adminMenuButton"
            >
              <font-awesome-icon icon="cogs" fixed-width></font-awesome-icon>
            </a>
            <el-badge v-if="unreadNotifications.length !== 0" :is-dot="true">
              <a
                class="avatar-wrapper has-cursor-pointer"
                @click="userMenu = !userMenu"
                ref="userMenuButton"
              >
                <div class="avatar">
                  <img v-if="avatar" :src="avatar" />
                  <avatar
                    v-else
                    :username="user.name ? user.name : ''"
                    :size="38"
                    :rounded="false"
                    backgroundColor="#dddddd"
                    :color="$root.app.primaryColor"
                  ></avatar>
                </div>
              </a>
            </el-badge>
            <a
              v-else
              class="avatar-wrapper has-cursor-pointer"
              @click="userMenu = !userMenu"
              ref="userMenuButton"
            >
              <div class="avatar">
                <img v-if="avatar" :src="avatar" />
                <avatar
                  v-else
                  :username="user.name ? user.name : ''"
                  :size="38"
                  :rounded="false"
                  backgroundColor="#fafafa"
                  :color="$root.primary"
                ></avatar>
              </div>
            </a>
          </el-row>
        </div>
      </div>
      <transition name="fade">
        <div class="user-menu" v-if="userMenu" ref="userMenu">
          <ul>
            <router-link :to="{ name: 'user' }">
              <li>
                <font-awesome-icon icon="pencil-alt" fixed-width>
                </font-awesome-icon>
                Edit Profile
              </li>
            </router-link>
            <router-link :to="{ name: 'support' }">
              <li>
                <font-awesome-icon
                  :icon="['far', 'life-ring']"
                  fixed-width
                ></font-awesome-icon>
                Support
              </li>
            </router-link>
            <a class="has-cursor-pointer" @click="openNotificationsModal">
              <li class="display: flex; align-items: center;">
                <font-awesome-icon
                  :icon="
                    unreadNotifications.length !== 0
                      ? 'envelope-open'
                      : 'envelope'
                  "
                  fixed-width
                ></font-awesome-icon>
                Notifications
                <el-badge
                  v-if="unreadNotifications.length !== 0"
                  class="mark"
                  :value="unreadNotifications.length"
                  :max="10"
                ></el-badge>
              </li>
            </a>
            <a class="has-cursor-pointer" @click="logout">
              <li>
                <font-awesome-icon
                  icon="sign-out-alt"
                  fixed-width
                ></font-awesome-icon>
                Logout
              </li>
            </a>
          </ul>
        </div>
      </transition>
      <transition name="fade">
        <div class="admin-menu" v-if="adminMenu" ref="adminMenu">
          <ul>
            <router-link :to="{ name: 'admin_users' }">
              <li>
                <font-awesome-icon icon="users" fixed-width></font-awesome-icon>
                Users
              </li>
            </router-link>
            <router-link
              v-if="$auth.check('super')"
              :to="{ name: 'admin_companies' }"
            >
              <li>
                <font-awesome-icon
                  icon="building"
                  fixed-width
                ></font-awesome-icon>
                Companies
              </li>
            </router-link>
            <router-link
              v-if="$auth.check('super')"
              :to="{ name: 'admin_tracker' }"
            >
              <li>
                <font-awesome-icon
                  icon="chart-line"
                  fixed-width
                ></font-awesome-icon>
                Tracker
              </li>
            </router-link>
            <router-link
              v-if="$auth.check('super')"
              :to="{ name: 'admin_collections' }"
            >
              <li>
                <font-awesome-icon icon="boxes" fixed-width></font-awesome-icon>
                Collections
              </li>
            </router-link>
          </ul>
        </div>
      </transition>
      <transition name="fade">
        <div class="company-menu" v-if="companyMenu" ref="companyMenu">
          <ul>
            <router-link
              v-for="item in companies"
              :key="item.slug"
              :to="{ name: 'company', params: { company: item.slug } }"
              :style="{
                color: item.color
                  ? item.color + ' !important'
                  : '#303133 !important',
              }"
            >
              <li
                :class="{
                  'current-company': company && company.slug === item.slug,
                }"
              >
                <div class="logo">
                  <img v-if="item.primary_logo" :src="item.primary_logo" />
                  <font-awesome-icon
                    v-else
                    icon="building"
                    fixed-width
                  ></font-awesome-icon>
                </div>
                <div class="content">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div class="info">
                    {{ item.num_months }} month{{
                      item.num_months !== 1 ? "s" : null
                    }}, {{ item.num_campaigns }} campaign{{
                      item.num_campaigns !== 1 ? "s" : null
                    }}, {{ item.num_assets }} asset{{
                      item.num_assets !== 1 ? "s" : null
                    }}
                  </div>
                </div>
              </li>
            </router-link>
          </ul>
        </div>
      </transition>
    </el-row>
  </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 420px) {
  .nav-container {
    .logo-container {
      margin-right: 0 !important;
      border-right: transparent !important;
    }

    .right-container {
      margin-left: 0 !important;
      border-left: transparent !important;
    }
  }
}
</style>

<script>
import Breadcrumbs from "@/components/Breadcrumbs"
import NotificationList from "@/partials/Notifications/List"

import { EventBus } from "@/events"

import VueSticky from "vue-sticky"
import Avatar from "vue-avatar"
import { mapActions, mapGetters } from "vuex"

import svg from "@/theme/svg"

export default {
  name: "TopNav",

  directives: {
    sticky: VueSticky,
  },
  components: {
    Breadcrumbs,
    Avatar,
  },

  data() {
    return {
      userMenu: false,
      adminMenu: false,
      companyMenu: false,
      companyContainerHover: false,
      editing: false,
      windowWidth: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      companies: "companies/list",
      totalItems: "companies/totalItems",
      company: "companies/current",
    }),
    avatar() {
      return this.user.avatar !== null ? this.user.avatar : false
    },
    unreadNotifications() {
      return this.$_.filter(this.user.notifications, function (o) {
        return !o.read
      })
    },
    logos() {
      return {
        ts: svg.comet,
        ca: svg.comet,
      }
    },
  },
  watch: {
    $route() {
      this.userMenu = this.adminMenu = this.companyMenu = this.editing = false
    },
  },

  methods: {
    ...mapActions({
      revertState: "auth/revert",
    }),
    documentClick(e) {
      let target = e.target,
        el,
        btn
      if (this.userMenu) {
        (el = this.$refs.userMenu), (btn = this.$refs.userMenuButton)

        if (
          btn &&
          !btn.contains(target) &&
          el &&
          el !== target &&
          !el.contains(target)
        ) {
          this.userMenu = false
        }
      }

      if (this.adminMenu) {
        (el = this.$refs.adminMenu), (btn = this.$refs.adminMenuButton)

        if (
          btn &&
          !btn.contains(target) &&
          el &&
          el !== target &&
          !el.contains(target)
        ) {
          this.adminMenu = false
        }
      }

      if (this.companyMenu) {
        (el = this.$refs.companyMenu), (btn = this.$refs.companyButton)

        if (
          btn &&
          !btn.contains(target) &&
          el &&
          el !== target &&
          !el.contains(target)
        ) {
          this.companyMenu = false
        }
      }
    },
    logout() {
      this.$auth.logout({
        redirect: { name: "login" },
      })
      this.revertState()
    },
    toggleMonthEdit() {
      this.editing = !this.editing
      EventBus.$emit("month-edit", this.editing)
    },
    newMenu(command) {
      if (command === "campaign") EventBus.$emit("new-campaign-modal")
      if (command === "asset") EventBus.$emit("new-asset-modal")
    },
    openNotificationsModal() {
      this.$modal.show(
        NotificationList,
        {},
        {
          name: "notifications-modal",
          adaptive: true,
          height: "auto",
          scrollable: true,
          width: 1000,
        }
      )
    },
    companyMenuToggle() {
      this.companyMenu = !this.companyMenu
    },
  },

  created: function () {
    document.addEventListener("click", this.documentClick)
    document.addEventListener("touchend", this.documentClick)
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick)
    document.removeEventListener("touchend", this.documentClick)
  },
}
</script>
